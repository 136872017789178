*:focus {
  outline: #3487cb solid 1px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

*,
::after,
::before {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  color: #4a4a4a;
  overflow-x: hidden;
  font-weight: normal;
  font-family: Inter;
  font-size: 14px;
  line-height: 1.5715;
  &.modal-open {
    overflow: hidden;
  }
}

textarea {
  &:focus,
  &:active {
    outline: 0;
  }
}

input {
  overflow: visible;
  &:active,
  &:focus {
    outline: 0;
  }
  //placeholder
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #bdbdbd;
    opacity: 0.7; /* Firefox */
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #bdbdbd;
    opacity: 0.7; /* Firefox */
  }
  &:-ms-input-placeholder {
    /* Microsoft Edge */
    color: #bdbdbd;
    opacity: 0.7; /* Firefox */
  }
}

div {
  box-sizing: border-box;
  display: block;
}

div[disabled],
li[disabled] {
  pointer-events: none;
  opacity: 0.4;
}

button {
  box-shadow: none !important;
}

*:focus {
  outline: 0;
}

// custom scrollbar
::-webkit-scrollbar {
  width: 3px;
  height: 8px;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.3);
  --box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.3);
}

small,
span,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.5715;
  font-weight: 400;
}

small {
  font-size: 12px;
}
