@import '~antd/dist/antd.less';

.rbc-time-content {
  flex: 1 0 0;
}

.grecaptcha-badge {
  display: none;
}

.site-input-group-wrapper .site-input-split {
  background-color: #fff;
}

.site-input-group-wrapper .site-input-right {
  border-left-width: 0;
}

.site-input-group-wrapper .site-input-right:hover,
.site-input-group-wrapper .site-input-right:focus {
  border-left-width: 1px;
}

.site-input-group-wrapper .ant-input-rtl.site-input-right {
  border-right-width: 0;
}

.site-input-group-wrapper .ant-input-rtl.site-input-right:hover,
.site-input-group-wrapper .ant-input-rtl.site-input-right:focus {
  border-right-width: 1px;
}

.ant-table-sticky-scroll {
  border: 0;
  opacity: 1;
  & .ant-table-sticky-scroll-bar {
    height: 8px;
    background: rgba(0, 0, 0, 0.5);
  }
}

.ant-table-summary.ant-table-sticky-holder {
  bottom: 20px !important;
}

.hidden-scrollbar-x {
  & .ant-table-body::-webkit-scrollbar {
    display: none;
  }
}

.ant-btn > .custom-icon + span,
.ant-btn > span + .custom-icon {
  margin-left: 4px;
}

.ant-btn > .custom-icon {
  line-height: 1 !important;
  vertical-align: -1px !important;
}

.rotate-180-deg {
  transform: rotate(180deg);
}

.disabled {
  opacity: 0.4;
}

.upload {
  position: relative;
  width: 100%;
  min-height: 130px;
  &__item {
    position: relative;
    border-radius: 5px;
    &__action {
      background: rgba(0, 0, 0, 0.3);
      border-radius: 5px;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: none;
      & svg:hover,
      & .icon:hover {
        cursor: pointer;
        fill: #ffffff;
        color: #ffffff;
      }
    }
    &:hover &__action {
      display: flex;
    }
  }
}

.ant-menu-inline-collapsed-tooltip .ant-tooltip-inner a {
  color: #fff !important;
}

.ant-picker-cell .ant-picker-cell-inner {
  height: auto;
}

tr.table-row-disable {
  background-color: #f0f0f0;
  color: #b6b6b6;
  > td {
    background-color: #f0f0f0;
  }
}
.ant-table-tbody > tr.ant-table-row.table-row-disable:hover > td, .ant-table-tbody > tr.table-row-disable > td.ant-table-cell-row-hover {
  background-color: #f0f0f0;
}

@font-family: Inter;@border-radius-base: 4px;@primary-color: #006B32;@border-color-base: #ddd;@form-vertical-label-padding: 0px;@form-item-margin-bottom: 17px;@form-item-label-font-size: 12px;@table-padding-vertical: 4px;@table-padding-horizontal: 4px;@table-font-size: 13px;@table-header-bg: #f8fafb;@table-sticky-scroll-bar-radius: 3px;@modal-header-padding: 16px 24px;@modal-body-padding: 16px 24px;@modal-footer-padding-vertical: 16px;@modal-footer-padding-horizontal: 24px;@menu-dark-bg: #006b32;@menu-dark-inline-submenu-bg: #367D50;@menu-dark-item-active-bg: #8DB297;@breadcrumb-font-size: 0.85em;@breadcrumb-icon-font-size: 0.85em;@label-color: #7f8d9e;@tabs-horizontal-padding: @padding-sm 14px;@layout-header-height: 50px;